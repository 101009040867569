import {NextPage} from 'next';
import {useRouter} from 'next/router';
import {useEffect} from 'react';
import * as settings from 'settings';

const Index: NextPage = () => {
    const router = useRouter();

    useEffect(() => {
        router.push('/home');
        //window.location.replace(`${settings.SSO_UI_URL}/login?next=${window.location}`);
    }, [router]);

    return null;
};

export default Index;


/* import {Component} from 'react';

export default class Login extends Component {
    componentWillMount() {
        window.location = `${window.SSO_UI_URL}/login?next=${window.location}`;
    }

    render() {
        return null;
    }
} */